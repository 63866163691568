.portfolioimagecontainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 70px;
}

.portfolioimagecontainer img {
  margin: 0;
  box-sizing: border-box;
  margin-bottom: 20px; /* Add space below each image */
}

.custom-class-1,
.custom-class-2 {
  flex: 0 0 calc(50% - 10px); /* 50% width with a little margin */
  margin-bottom: 20px; /* Add space below custom-class-1 and custom-class-2 images */
}

.custom-class-1 img,
.custom-class-2 img {
  width: 100%;
  height: auto;
  display: block;
}

.portfolioimagecontainer img:not(.custom-class-1):not(.custom-class-2) {
  flex: 0 0 calc(33.333% - 10px); /* 33.333% width with a little margin */
  margin-bottom: 20px; /* Add space below other images */
}

.portfoliocontainer {
  max-width: 100%;
  overflow: hidden;
}

.center-container {
  flex: 1;
  padding: 20px 150px;
  box-sizing: border-box;
  text-align: center;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .portfolioimagecontainer img:not(.custom-class-1):not(.custom-class-2) {
    flex: 0 0 calc(50% - 10px); /* 50% width with a little margin for small screens */
  }
}

@media (max-width: 576px) {
  .custom-class-1,
  .custom-class-2,
  .portfolioimagecontainer img:not(.custom-class-1):not(.custom-class-2) {
    flex: 0 0 calc(100% - 10px); /* 100% width for extra small screens */
  }
  .portfolioimagecontainer
    img:not(.custom-class-1):not(.custom-class-2):last-child {
    margin-bottom: 0;
  }
}

.imageHeader {
  height: 60px;
  width: 200px;
  object-fit: cover;
  margin: 20px 60px;
}
.fixed-image {
  position: fixed;
  bottom: 20px;
  right: 8%;
  z-index: 1000;
  cursor: pointer;
}
.center-container img {
  padding: 16px;
}
