.group-child20 {
  border-radius: var(--br-xl);
  width: 406px;
  height: 548px;
  object-fit: cover;
}
.bride-portraits {
  line-height: 190.9%;
  text-align: center;
}
.rectangle-parent7 {
  height: 541px;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color2);
  font-family: var(--font-arkhip);
}
