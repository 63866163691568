.contactusmaincont {
  margin: 40px 80px;
}

.details {
  width: 100%;

  /* font-size: 16px; */
  /* margin: 40px 80px; */
  font-size: 20px;
}
.datailadd {
  /* width: 720px; */
  height: 200px;
  width: 470px;
}
.contact {
  width: 420px;
  height: 200px;
}
.opening {
  width: 430px;
  height: 200px;
}
.detailshead {
  font-size: var(--font-size-11xl);
  font-weight: 600;
  font-family: var(--font-piazzolla);
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contentcontact {
  /* font-size: 22px; */
  font-size: var(--font-size-xl);
  font-family: var(--font-montserrat);
  line-height: 30px;
}
.address-main {
  /* width: 90%; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: var(--font-size-xl);
  font-family: var(--font-montserrat);
  line-height: 30px;
}
.street-addr {
  font-size: 18px;
  font-family: var(--font-montserrat);
  line-height: 30px;
  padding: 10px 0;
}
.contact-addr {
  font-size: 18px;
  font-family: var(--font-montserrat);
  line-height: 30px;
  padding: 5px 0;
}
.date-time1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 18px;
  font-family: var(--font-montserrat);
  line-height: 30px;
  padding: 10px 0;
}
.date-time2 {
  display: flex;
  padding: 10px 0;
  flex-direction: row;
  justify-content: space-between;
  font-size: 18px;
  font-family: var(--font-montserrat);
  line-height: 30px;
  width: 305px;
}
.social-icons {
  display: flex;
  justify-content: space-around;
}

.icon {
  text-align: center;
}

.icon-image {
  width: 50px; /* Adjust the size as needed */
  height: 50px; /* Adjust the size as needed */
}

.icon-link {
  display: block;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  margin-top: 5px;
}

/* Add styles for hover effects if desired */
.icon-link:hover {
  color: #0078d4; /* Change the color on hover */
}
.iconstyles {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
  width: 400px;
}
.contactInfo {
  font-size: 30px;
  font-weight: 500;
  font-variant: small-caps;
  font-feature-settings: "smcp";
}
.description {
  font-size: var(--font-size-xl);
  font-family: var(--font-montserrat);
  line-height: 30px;
  margin: 40px 80px;
  padding: 20px 0;
}
