.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.logo-for-post-24 {
  /* width: 200px; */
  height: 60px;
  /* object-fit: cover; */
}
.about-us,
.contact-us1,
.portflio,
.services {
  font-weight: 500;
  cursor: pointer;
}
.contact-us1,
.portflio,
.services {
  left: 113px;
}
.contact-us1,
.services {
  left: 210px;
}
.contact-us1 {
  left: 314px;
}
.about-us-parent {
  /* left: 908px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 404px;
  height: 20px;
}
.logo-for-post-2-parent {
  padding: 20px 20px;
  top: 60px;

  height: 60px;
  font-size: var(--font-size-base);
  font-family: var(--font-montserrat);
}
.about-us1,
.welcome-to-dream {
  /* position: absolute;
  top: 0;
  left: 0; */
  margin-bottom: 38px;
}
.welcome-to-dream {
  /* top: 69px; */
  font-size: var(--font-size-11xl);
  font-family: var(--font-playfair-display-sc);
}
.our-team-of {
  margin: 10px 0;
}
.welcome-to-dream-container {
  /* position: absolute;
  top: 142px;
  left: 0; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: var(--font-size-xl);
  line-height: 190.9%;
  font-family: var(--font-montserrat);
  /* display: inline-block; */
  /* width: 1312px; */
}
.about-us-group {
  align-items: center;
  width: 1312px;
  height: 598px;
}
.our-mission,
.our-vision {
  /* left: 242px; */
  text-align: center;
  font-weight: 600;
  margin-bottom: 38px;
}
.our-vision {
  left: 922px;
}
.at-dream-big1,
.our-mission-is {
  text-align: center;
  font-size: var(--font-size-base);
  line-height: 190.9%;
  font-family: var(--font-montserrat);
  display: inline-block;
  width: 645px;
}
.at-dream-big1 {
  left: 669px;
  width: 644px;
}

.our-mission-parent {
  /* position: absolute;
  top: 658px;
  left: 0;
  width: 1313px; */
  /* height: 259px; */
  margin: 40px 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  font-size: var(--font-size-11xl);
  font-family: var(--font-piazzolla);
}
.about-us-g {
  /* top: 778px;
  left: 63px;
  height: 917px; */
  margin: 40px 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: var(--font-size-5xl);
  font-family: var(--font-arkhip);
}
.our-service {
  /* left: calc(50% - 74px); */
  font-weight: 600;
  margin-bottom: 38px;
}
.at-dream-big2 {
  font-size: var(--font-size-xl);
  line-height: 133.9%;
  font-family: var(--font-montserrat);
  text-align: center;
  display: inline-block;
}
.our-service-parent {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 40px 80px;
}
.group-child16 {
  border-radius: var(--br-xl);
  width: 406px;
  height: 548px;
  object-fit: cover;
}
.wedding-photography1 {
  line-height: 190.9%;
  text-align: center;
}
.rectangle-parent4 {
  height: 541px;
}
.engagement {
  line-height: 190.9%;
  text-align: center;
}
.rectangle-parent5 {
  height: 541px;
}
.pre-wedding-photography {
  text-align: center;
  line-height: 190.9%;
}
.group-parent2,
.group-parent3,
.rectangle-parent6 {
}

.group-parent3 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 100px;
  flex-wrap: wrap;
}

.group-parent2 {
  width: 100%;
  font-size: var(--font-size-base);
  font-family: var(--font-arkhip);
}
.group-parent1 {
  position: absolute;
  top: 1795px;
  left: 63px;
  width: 1312px;
  height: 1957px;
}
.goverment-project {
  text-align: center;
  font-weight: 600;
  margin-bottom: 40px;
}
.madurai-natham,
.nhai-project {
  /* position: absolute;
  top: 58px;
  left: 0; */
}
.nhai-project {
  top: 0;
  left: 68px;
  font-size: 40px;
}
.group-child19,
.madurai-natham-flyover-parent {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-feature-settings: "smcp";
  font-variant: small-caps;
}
.group-child19 {
  top: -16px;
  left: 533px;
  border-radius: var(--br-xl);
  width: 581px;
  height: 609px;
  object-fit: cover;
  cursor: pointer;
}
.group-parent4 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* position: absolute;
  top: 103px;
  left: 0;
  width: 1090px;
  height: 561px; */
  flex-wrap: wrap;
  font-family: var(--font-arkhip);
}
.goverment-project-parent {
  /* position: absolute;
  top: 3852px;
  left: 63px;
  width: 1090px;
  height: 664px; */
  flex-wrap: wrap;
}
.msg-icon2 {
  width: 60px;
  height: 60px;
  overflow: hidden;
  cursor: pointer;
}
.home {
  background-color: var(--color1);
  width: 100%;
  height: auto;
  text-align: left;
  font-size: var(--font-size-11xl);
  color: var(--color2);
  font-family: var(--font-piazzolla);
}
