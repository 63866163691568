.our-process {
  /* position: absolute;
  top: 0;
  left: calc(50% - 74px); */
  text-align: center;
  font-weight: 600;
  margin-bottom: 38px;
}
.at-dream-big3 {
  /* position: absolute;
  top: 73px;
  left: 0; */
  font-size: var(--font-size-xl);
  line-height: 133.9%;
  font-family: var(--font-montserrat);
  text-align: center;
  display: inline-block;
  /* width: 1312px; */
}
.our-process-parent {
  /* height: 154px; */
  margin: 100px 0px;
}
.initial-consultation {
}
.customized-planning,
.the-shoot {
  /* position: absolute;
  top: 0;
  left: 323px; */
}
.the-shoot {
  /* left: 650px; */
}
.final-delivery,
.post-production {
  /* position: absolute;
  top: 0;
  left: 856px; */
}
.final-delivery {
  /* left: 1145px; */
}
.initial-consultation-parent {
  height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-variant: small-caps;
  padding: 20px 10px;
  font-weight: bolder;
  letter-spacing: 2px;
}
.group-icon,
.group-parent8::first-letter {
  /* position: absolute;
  top: 44px;
  left: 92px;
  width: 1162px; */
  /* height: 50px; */
  padding: 0px 20px;
  width: 92%;
}
.group-parent8 {
  font-size: var(--font-size-xl);
  font-family: var(--font-arkhip);
}
.group-parent7 {
  /* position: absolute;
  top: 4616px;
  left: 63px;
  width: 1312px;
  height: 308px; */
  margin: 40px 80px;
  text-align: left;
  font-size: var(--font-size-11xl);
  color: var(--color2);
  font-family: var(--font-piazzolla);
}
