.address,
.no-35-1st {
  position: absolute;
  left: 0;
}
.address {
  top: 0;
  font-weight: 600;
  font-family: var(--font-piazzolla);
}
.no-35-1st {
  top: 63px;
  font-size: var(--font-size-base);
  display: inline-block;
  width: 421px;
}
.google-map-code {
  font-weight: 500;
}
.span {
  font-size: var(--font-size-base);
}
.address-parent,
.google-map-code-container {
  position: absolute;
  top: 143px;
  left: 0;
  font-size: var(--font-size-xl);
}
.address-parent {
  top: 0;
  width: 421px;
  height: 173px;
  font-size: var(--font-size-11xl);
}
.opening-hours {
  font-size: var(--font-size-11xl);
  font-weight: 600;
  font-family: var(--font-piazzolla);
}
.closed,
.opening-hours,
.sunday,
.sunday-parent {
  position: absolute;
  top: 0;
  left: 0;
}
.closed,
.sunday-parent {
  left: 223px;
}
.sunday-parent {
  top: 107px;
  left: 0;
  width: 279px;
  height: 24px;
}
.monday-saturday-parent,
.opening-hours-parent {
  position: absolute;
  top: 63px;
  left: 0;
  width: 393px;
  height: 24px;
}
.opening-hours-parent {
  top: 0;
  left: 877px;
  height: 131px;
}
.div7,
.div8 {
  position: absolute;
  top: 63px;
  left: 0;
}
.div8 {
  top: 107px;
}
.dreambigstudioingmailcom {
  position: absolute;
  top: 151px;
  left: 0;
  text-decoration: underline;
  color: inherit;
}
.contact-parent {
  position: absolute;
  top: 0;
  left: 493px;
  width: 249px;
  height: 175px;
}
.group-parent11,
.vector-icon,
.vector-icon1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 1270px;
  height: 175px;
}
.vector-icon,
.vector-icon1 {
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon1 {
  height: 69.31%;
  width: 32.61%;
  top: 15.35%;
  right: 33.69%;
  bottom: 15.35%;
  left: 33.69%;
}
.fb {
  position: absolute;
  top: 0;
  left: 16%;
  text-decoration: underline;
  color: inherit;
  display: none;
}
.facebook-3-logo-svgrepo-com-1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  overflow: hidden;
  font-size: 33.65px;
}
.vector-icon2,
.vector-icon3,
.vector-icon4,
.vector-icon5 {
  position: absolute;
  height: 100%;
  width: 99.55%;
  top: 0;
  right: 0.22%;
  bottom: 0;
  left: 0.22%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon3,
.vector-icon4,
.vector-icon5 {
  height: 95.43%;
  width: 95%;
  top: 1.72%;
  right: 2.5%;
  bottom: 2.85%;
  left: 2.5%;
}
.vector-icon4,
.vector-icon5 {
  height: 46.15%;
  width: 49.76%;
  top: 26.37%;
  right: 24.93%;
  bottom: 27.48%;
  left: 25.31%;
}
.vector-icon5 {
  height: 98.85%;
  width: 98.41%;
  top: 0.01%;
  right: 0.79%;
  bottom: 1.14%;
  left: 0.79%;
}
.wa {
  position: absolute;
  top: 0;
  left: 0;
  text-decoration: underline;
  color: inherit;
  display: none;
}
.whatsapp-symbol-logo-svgrepo-c {
  position: absolute;
  top: 0;
  left: 110px;
  width: 50px;
  height: 50px;
  overflow: hidden;
}
.vector-icon6,
.vector-icon7,
.vector-icon8 {
  position: absolute;
  height: 28.14%;
  width: 26.96%;
  top: 34.86%;
  right: 33.35%;
  bottom: 37%;
  left: 39.69%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon7,
.vector-icon8 {
  height: 16.59%;
  width: 26.92%;
  right: 33.39%;
  bottom: 48.55%;
}
.vector-icon8 {
  height: 70.23%;
  width: 100%;
  top: 14.88%;
  right: 0;
  bottom: 14.89%;
  left: 0;
}
.yt {
  position: absolute;
  top: 2%;
  left: 22%;
  text-decoration: underline;
  color: inherit;
  display: none;
}
.youtube-icon-logo-svgrepo-com {
  position: absolute;
  top: 0;
  left: 220px;
  width: 50px;
  height: 50px;
  overflow: hidden;
}
.vector-icon12,
.vector-icon13,
.vector-icon14,
.vector-icon9 {
  position: absolute;
  height: 87.5%;
  width: 87.5%;
  top: 6.25%;
  right: 6.25%;
  bottom: 6.25%;
  left: 6.25%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon12,
.vector-icon13,
.vector-icon14 {
  height: 9.38%;
  width: 9.38%;
  top: 28.13%;
  right: 28.13%;
  bottom: 62.5%;
  left: 62.5%;
}
.vector-icon13,
.vector-icon14 {
  height: 31.25%;
  width: 31.25%;
  top: 34.38%;
  right: 34.38%;
  bottom: 34.38%;
  left: 34.38%;
}
.vector-icon14 {
  height: 62.5%;
  width: 62.5%;
  top: 18.75%;
  right: 18.75%;
  bottom: 18.75%;
  left: 18.75%;
}
.ig {
  position: absolute;
  top: 0;
  left: 22%;
  text-decoration: underline;
  color: inherit;
  display: none;
}
.instagram-1-svgrepo-com-1 {
  position: absolute;
  top: 0;
  left: 330px;
  width: 50px;
  height: 50px;
  overflow: hidden;
}
.facebook-3-logo-svgrepo-com-1-parent {
  position: absolute;
  top: 205px;
  left: 0;
  width: 380px;
  height: 50px;
  font-size: var(--font-size-11xl);
}
.group-parent10,
.group-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 1270px;
  height: 255px;
}
.group-wrapper {
  top: 170px;
}
.thank-you-for {
  position: absolute;
  top: 59px;
  left: 0;
  font-size: var(--font-size-xl);
  line-height: 133.9%;
  font-family: var(--font-montserrat);
  display: inline-block;
  width: 1312px;
}
.contact-us-parent {
  top: 0;
  left: 0;
  height: 140px;
  font-size: var(--font-size-5xl);
  font-family: var(--font-arkhip);
}
.contact-us-parent,
.group-parent9,
.let-us-join {
  position: absolute;
  width: 1312px;
}
.let-us-join {
  top: 455px;
  left: 0;
  font-size: var(--font-size-xl);
  line-height: 133.9%;
  font-weight: 500;
  font-family: var(--font-montserrat);
  display: inline-block;
}
.group-parent9 {
  top: 5824px;
  left: 64px;
  height: 509px;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color2);
  font-family: var(--font-poppins);
}
