.dbs-2404-1-icon {
  width: 100%;
  object-fit: cover;
}
.welcome-to1 {
  font-weight: 600;
}
.welcome-to {
  margin: 0;
  font-size: 20px;
}
.dream-big-studio {
  margin: 0;
  font-size: 30px;
  font-feature-settings: "smcp";
  font-variant: small-caps;
}
.unlocking-the-magic {
  font-size: 36px;
  font-family: var(--font-arkhip);
  font-feature-settings: "smcp";
  font-variant: small-caps;
  font-weight: 400;
}
.welcome-to-dream-big-studio-parent {
  height: 208px;
  width: 100%;
}
.content {
  width: 100%;
  text-align: center;
}
.div,
.div1,
.div2,
.div3,
.div4,
.div5 {
  height: 500px;
}
.welcome-to-dream-big-container {
  position: absolute;
  top: -220px;
  text-align: left;
  padding: 0 30px;
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.property-1slider-1 {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 500px;
  overflow: hidden;
}

.carousel-item {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.carousel-item.active {
  display: block;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.dbs-2404-1-icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}
