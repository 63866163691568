.group-child24 {
  border-radius: var(--br-xl);
  width: 406px;
  height: 548px;
  object-fit: cover;
}
.post-wedding-photography {
  text-align: center;
  line-height: 190.9%;
}
.rectangle-parent10 {
  height: 541px;
}
.maternity-shoot {
  text-align: center;
  line-height: 190.9%;
}
.rectangle-parent11 {
  height: 541px;
}
.group-parent6 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: left;
  flex-wrap: wrap;
  margin-bottom: 100px;
  font-size: var(--font-size-base);
  color: var(--color2);
  font-family: var(--font-arkhip);
}
