.logo-for-post-2 {
  position: absolute;
  top: 60px;
  left: 64px;
  width: 200px;
  height: 60px;
  object-fit: cover;
  cursor: pointer;
}
.goverment-projects-child,
.goverment-projects-inner,
.goverment-projects-item {
  position: absolute;
  top: 178px;
  left: 64px;
  width: 644px;
  height: 366px;
  object-fit: cover;
}
.goverment-projects-inner,
.goverment-projects-item {
  top: 574px;
}
.goverment-projects-inner {
  top: 970px;
}
.goverment-projects-child1,
.goverment-projects-child2,
.rectangle-icon {
  position: absolute;
  top: 178px;
  left: 732px;
  width: 644px;
  height: 366px;
  object-fit: cover;
}
.goverment-projects-child1,
.goverment-projects-child2 {
  top: 574px;
}
.goverment-projects-child2 {
  top: 970px;
}
.msg-icon {
  position: absolute;
  bottom: 60px;
  left: 1316px;
  width: 60px;
  height: 60px;
  overflow: hidden;
  cursor: pointer;
}
.goverment-projects {
  position: relative;
  background-color: var(--color1);
  width: 100%;
  height: 1436px;
  overflow: hidden;
}
